@import './swiper.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    /* default theme */
    :root {
        /*
          - gray/natural colors for texts, borders and disabled elements.
          - If required we can use as background too.
        */
        /* For rgb(255 255 255 / <alpha-value>) = #ffffff */
        --gray-0: 255 255 255;
        /* For rgb(250 250 250 / <alpha-value>) = #fafafa */
        --gray-50: 250 250 250;
        /* For rgb(241 241 241 / <alpha-value>) = #f1f1f1 */
        --gray-100: 241 241 241;
        /* For rgb(227 227 227 / <alpha-value>) = #e3e3e3 */
        --gray-200: 227 227 227;
        /* For rgb(223 223 223 / <alpha-value>) = #dfdfdf */
        --gray-300: 223 223 223;
        /* For rgb(146 146 146 / <alpha-value>) = #929292 */
        --gray-400: 146 146 146;
        /* For rgb(102 102 102 / <alpha-value>) = #666666 */
        --gray-500: 102 102 102;
        /* For rgb(72 72 72 / <alpha-value>) = #484848 */
        --gray-600: 72 72 72;
        /* For rgb(51 51 51 / <alpha-value>) = #333333 */
        --gray-700: 51 51 51;
        /* For rgb(34 34 34 / <alpha-value>) = #222222 */
        --gray-800: 34 34 34;
        /* For rgb(17 17 17 / <alpha-value>) = #111111 */
        --gray-900: 17 17 17;
        /* For rgb(0 0 0 / <alpha-value>) = #000000 */
        --gray-1000: 0 0 0;

        /* body style */
        --background: 255 255 255; /* #ffffff */
        --foreground: 72 72 72; /* #484848 */
        --muted: 227 227 227; /* #e3e3e3 */
        --muted-foreground: 146 146 146; /* #929292 */

        /* ----------------------------------- */
        /* primary/brand colors */
        /* ----------------------------------- */
        --primary-lighter: 227 227 227; /* #e3e3e3 */
        --primary-default: 17 17 17; /* #111111 */
        --primary-dark: 0 0 0; /* #000000 */
        --primary-foreground: 255 255 255; /* #ffffff */

        /* ----------------------------------- */
        /* secondary colors */
        /* ----------------------------------- */
        --secondary-lighter: 221 227 255; /* #dde3ff */
        --secondary-default: 78 54 245; /* #4e36f5 */
        --secondary-dark: 67 42 216; /* #432ad8 */
        --secondary-foreground: 255 255 255; /* #ffffff */

        /* ----------------------------------- */
        /* red/error colors */
        /* ----------------------------------- */
        --red-lighter: 247 212 214; /* #f7d4d6 */
        --red-default: 238 0 0; /* #e00 */
        --red-dark: 197 0 0; /* #c50000 */

        /* ----------------------------------- */
        /* orange/warning colors */
        /* ----------------------------------- */
        --orange-lighter: 255 239 207; /* #ffefcf */
        --orange-default: 245 166 35; /* #f5a623 */
        --orange-dark: 171 87 10; /* #ab570a */

        /* ----------------------------------- */
        /* blue/info colors */
        /* ----------------------------------- */
        --blue-lighter: 211 229 255; /* #d3e5ff */
        --blue-default: 0 112 243; /* #0070f3 */
        --blue-dark: 7 97 209; /* #0761d1 */

        /* ----------------------------------- */
        /* green/success colors */
        /* ----------------------------------- */
        --green-lighter: 185 249 207; /* #b9f9cf */
        --green-default: 17 168 73; /* #11a849 */
        --green-dark: 17 132 60; /* #11843c */
    }

    /* dark theme */
    [data-theme='dark'] {
        /*
          - gray/natural color shades for texts, borders and disabled elements.
          - If required we can use as background too.
        */
        /* For rgb(0 0 0 / <alpha-value>) = #000000  */
        --gray-0: 0 0 0;
        /* For rgb(17 17 17 / <alpha-value>) = #111111 */
        --gray-50: 17 17 17;
        /* For rgb(31 31 31 / <alpha-value>) = #1f1f1f */
        --gray-100: 31 31 31;
        /* For rgb(51 51 51 / <alpha-value>) = #333333 */
        --gray-200: 51 51 51;
        /* For rgb(72 72 72 / <alpha-value>) = #484848 */
        --gray-300: 72 72 72;
        /* For rgb(102 102 102 / <alpha-value>) = #666666 */
        --gray-400: 102 102 102;
        /* For rgb(146 146 146 / <alpha-value>) = #929292 */
        --gray-500: 146 146 146;
        /* For rgb(162 162 162 / <alpha-value>) = #a2a2a2 */
        --gray-600: 162 162 162;
        /* For rgb(223 223 223 / <alpha-value>) = #dfdfdf */
        --gray-700: 223 223 223;
        /* For rgb(226 226 226 / <alpha-value>) = #e2e2e2 */
        --gray-800: 226 226 226;
        /* For rgb(241 241 241 / <alpha-value>) = #f1f1f1 */
        --gray-900: 241 241 241;
        /* For rgb(255 255 255 / <alpha-value>) = #ffffff */
        --gray-1000: 255 255 255;

        --background: 8 9 14; /* #08090e */
        --foreground: 223 223 223; /* #dfdfdf */
        --muted: 51 51 51; /* #333333 */
        --muted-foreground: 102 102 102; /* #666666 */

        /* ----------------------------------- */
        /* primary/brand colors */
        /* ----------------------------------- */
        --primary-lighter: 34 34 34; /* #222222 */
        --primary-default: 241 241 241; /* #f1f1f1 */
        --primary-dark: 255 255 255; /* #ffffff */
        --primary-foreground: 0 0 0; /* #000000 */

        /* ----------------------------------- */
        /* secondary colors */
        /* ----------------------------------- */
        --secondary-lighter: 31 22 90; /* #1f165a */
        --secondary-dark: 193 203 255; /* #c1cbff */

        /* ----------------------------------- */
        /* red/error colors */
        /* ----------------------------------- */
        --red-lighter: 80 0 0; /* #500000 */
        --red-dark: 255 193 193; /* #ffc1c1 */

        /* ----------------------------------- */
        /* orange/warning colors */
        /* ----------------------------------- */
        --orange-lighter: 68 29 4; /* #441d04 */
        --orange-dark: 252 234 139; /* #fcea8b */

        /* ----------------------------------- */
        /* blue/info colors */
        /* ----------------------------------- */
        --blue-lighter: 13 51 94; /* #0d335e */
        --blue-dark: 181 233 255; /* #b5e9ff */

        /* ----------------------------------- */
        /* green/success colors */
        /* ----------------------------------- */
        --green-lighter: 3 48 22; /* #033016 */
        --green-dark: 185 249 207; /* #b9f9cf */

        /* here you can customize other colors for dark theme if design required */
    }

    .rizzui-select-options {
        z-index: 9999 !important;
    }

    /* base style */
    html {
        @apply h-full antialiased;
    }

    #player {
        @apply dark:bg-gray-50;
    }

    body {
        @apply flex min-h-full flex-col overflow-x-hidden text-sm text-gray-600 dark:bg-gray-50;
    }

    input::-ms-clear,
    input::-ms-reveal {
        @apply hidden;
    }

    input[type='search']::-webkit-search-cancel-button {
        @apply hidden;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        @apply m-0 appearance-none;
    }

    input[type='checkbox']:checked {
        @apply bg-none;
    }

    /* typography style for the Text component */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-lexend font-bold text-gray-900 dark:text-gray-700;
    }

    h1 {
        @apply text-3xl dark:text-gray-800 2xl:text-[32px] 4xl:text-4xl;
    }

    h2 {
        @apply text-2xl dark:text-gray-800 2xl:text-[26px] 4xl:text-3xl;
    }

    h3 {
        @apply text-xl 2xl:text-[22px] 4xl:text-2xl;
    }

    h4 {
        @apply text-lg 2xl:xl:text-xl;
    }

    h5 {
        @apply text-base 2xl:text-lg;
    }

    h6 {
        @apply text-sm 2xl:text-base;
    }

    q {
        @apply text-lg;
    }
}

@layer utilities {
    .fill-opacity-10 {
        fill-opacity: 0.1;
    }

    .fill-opacity-20 {
        fill-opacity: 0.2;
    }

    .fill-opacity-30 {
        fill-opacity: 0.3;
    }

    .fill-opacity-60 {
        fill-opacity: 0.6;
    }

    /* animation delay required for the Loader component */
    .animation-delay-200 {
        animation-delay: 200ms;
    }

    .animation-delay-500 {
        animation-delay: 500ms;
    }

    .animation-delay-700 {
        animation-delay: 700ms;
    }

    /* this utility class required for the PinCode component */
    .password-dot {
        -webkit-text-security: disc;
        -moz-text-security: circle;
        text-security: circle;
    }

    /* experimental */
    .card-shadow {
        box-shadow:
            0px 1px 0 rgba(0, 0, 0, 0.02),
            0px 2px 6px rgba(0, 0, 0, 0.05);
    }
}

/* ................................................... */
/* scrollbar design starts from here */
/* ................................................... */
.simplebar-scrollbar:before {
    @apply !bg-gray-400 dark:!bg-gray-300;
}

.simplebar-track.simplebar-horizontal {
    @apply !h-2.5;
}

.simplebar-track.simplebar-vertical {
    @apply !w-2.5;
}

.custom-scrollbar::-webkit-scrollbar,
.rc-table-fixed-header .rc-table-body::-webkit-scrollbar {
    @apply w-1.5 bg-transparent;
}

.custom-scrollbar::-webkit-scrollbar-track,
.rc-table-content::-webkit-scrollbar-track,
.rc-table-fixed-header .rc-table-body::-webkit-scrollbar-track {
    @apply bg-transparent shadow-none;
}

.custom-scrollbar::-webkit-scrollbar-thumb,
.rc-table-content::-webkit-scrollbar-thumb,
.rc-table-fixed-header .rc-table-body::-webkit-scrollbar-thumb {
    @apply rounded-sm bg-transparent;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb,
.rc-table-content:hover::-webkit-scrollbar-thumb,
.rc-table-fixed-header .rc-table-body:hover::-webkit-scrollbar-thumb {
    @apply bg-gray-300 dark:bg-gray-300;
}

.custom-scrollbar.scrollbar-no-auto-hide::-webkit-scrollbar-thumb {
    @apply bg-gray-300 dark:bg-gray-300;
}

.custom-scrollbar-x::-webkit-scrollbar,
.rc-table-content::-webkit-scrollbar,
.rc-table-fixed-header .rc-table-body::-webkit-scrollbar {
    @apply h-1.5;
}

.custom-scrollbar-none::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

/* for firefox */
.custom-scrollbar,
.rc-table-content {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0);
}

/* ................................................... */
/* Change autocomplete styles in WebKit */
/* ................................................... */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: currentColor;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

/* ................................................... */
/* ?React Datepicker Styling */
/* ................................................... */
.react-datepicker-popper .react-datepicker {
    @apply bg-gray-0 font-inter text-gray-600 dark:border-gray-300 dark:bg-gray-100;
}

/* month container */
.react-datepicker .react-datepicker__month-container {
    @apply px-3;
}

/* time container */
.react-datepicker .react-datepicker__time-container {
    @apply w-auto border-l-0 pr-3.5;
}

.react-datepicker-popper
    .react-datepicker__time-container
    .react-datepicker__time {
    @apply bg-gray-50 dark:bg-gray-200;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    @apply dark:bg-gray-300;
}

/* header */
.react-datepicker .react-datepicker__header {
    @apply border-b-gray-0 bg-gray-0 font-lexend dark:border-transparent dark:bg-gray-100;
}

/* current month name in header */
.react-datepicker .react-datepicker-year-header {
    @apply dark:text-gray-700;
}

.react-datepicker .react-datepicker__current-month {
    @apply mb-3 text-base font-medium dark:text-gray-700;
}

/* sun-sat day names in header */
.react-datepicker .react-datepicker__day-names div {
    @apply m-1.5 text-sm text-gray-700;
}

/* previous month button */
.react-datepicker .react-datepicker__navigation--previous {
    @apply ml-6 rtl:mr-6;
}

/* next month button */
.react-datepicker .react-datepicker__navigation--next {
    @apply mr-6 rtl:ml-6;
}

/* icon in previous month button */
.react-datepicker .react-datepicker__navigation-icon--previous {
    @apply right-0.5;
}

/* icon in next month button */
.react-datepicker .react-datepicker__navigation-icon--next {
    @apply left-0.5;
}

/* each day */
.react-datepicker .react-datepicker__day {
    @apply m-1.5 text-sm leading-7 text-gray-700;
    @apply hover:rounded-full hover:bg-gray-100 hover:text-gray-900;
}

/* outside month */
.react-datepicker .react-datepicker__day--outside-month {
    @apply text-gray-500;
}

/* keyboard selected */
.react-datepicker .react-datepicker__day--keyboard-selected {
    @apply bg-gray-0;
}

/* today */
.react-datepicker .react-datepicker__day--today {
    @apply rounded-full border border-gray-300 bg-gray-0 leading-[26px] text-gray-900;
    @apply hover:border-gray-600 hover:bg-gray-0;
}

/* while selecting */
.react-datepicker div.react-datepicker__day--in-selecting-range {
    @apply rounded-full bg-gray-200 text-gray-900;
}

.react-datepicker div.react-datepicker__year-text--in-selecting-range {
    @apply bg-gray-200 text-gray-900;
}

.react-datepicker
    div.react-datepicker__year-text--in-selecting-range.react-datepicker__year-text--disabled {
    @apply bg-transparent text-gray-300;
}

/* in range */
.react-datepicker .react-datepicker__day--in-range {
    @apply rounded-full bg-gray-200 text-gray-900;
}

.react-datepicker .react-datepicker__year-text--in-range {
    @apply bg-gray-200 text-gray-900;
}

/* selected */
.react-datepicker .react-datepicker__day--range-start,
.react-datepicker .react-datepicker__day--range-end,
.react-datepicker .react-datepicker__day--selected {
    @apply rounded-full border-none bg-gray-900 font-normal leading-7 text-gray-0;
    @apply hover:bg-gray-900/80 hover:text-gray-0;
}

.react-datepicker .react-datepicker__year-text--range-end {
    @apply border-none bg-gray-900 font-normal text-gray-0 hover:bg-gray-900/80 hover:text-gray-0;
}

/* time list */
.react-datepicker .react-datepicker__time-list {
    @apply !h-[247px];
}

/* time item */
.react-datepicker .react-datepicker__time-list-item {
    @apply my-2 rounded text-sm text-gray-500;
    @apply hover:bg-gray-100 hover:text-gray-900;
}

/* selected time item */
.react-datepicker .react-datepicker__time-list-item--selected {
    @apply !bg-gray-100 !font-medium !text-gray-900;
}

/* time only box */
.react-datepicker-popper .react-datepicker-time__header {
    @apply text-gray-700;
}
.react-datepicker-popper
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 90px;
}
.react-datepicker--time-only
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    @apply w-auto;
}

/* time only item */
.react-datepicker--time-only .react-datepicker__time-list-item {
    @apply rounded-none;
}

/* month picker text */
.react-datepicker .react-datepicker__month-text {
    @apply w-0 px-5 py-1.5;
}

/* keyboard selected month in month picker */
.react-datepicker .react-datepicker__month-text--keyboard-selected {
    @apply bg-gray-100 text-gray-900;
}

/* month in range */
.react-datepicker .react-datepicker__month--in-range {
    @apply bg-gray-200 text-gray-700;
}

/* hover on selected months in range */
.react-datepicker
    .react-datepicker__month-text.react-datepicker__month--in-range:hover {
    @apply bg-gray-200 text-gray-900;
}

/* selected month in range */
.react-datepicker .react-datepicker__month--range-start,
.react-datepicker .react-datepicker__month--range-end,
.react-datepicker .react-datepicker__month--selected {
    @apply bg-gray-900 font-normal text-gray-0;
}

/* hover on selected range start and end month */
.react-datepicker
    .react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker
    .react-datepicker__month-text.react-datepicker__month--range-start:hover,
.react-datepicker
    .react-datepicker__month-text.react-datepicker__month--range-end:hover {
    @apply bg-gray-900/80 text-gray-0;
}

/* year wrapper in year picker */
.react-datepicker .react-datepicker__year-wrapper {
    @apply inline-block max-w-[220px];
}

/* year text in year picker */
.react-datepicker .react-datepicker__year-text {
    @apply w-auto px-5 py-1.5 text-gray-600 dark:hover:text-gray-100;
}

.react-datepicker .react-datepicker__year-text--range-end {
    @apply text-gray-0;
}

/* keyboard selected year in year picker */
.react-datepicker .react-datepicker__year-text--keyboard-selected {
    @apply bg-gray-200 text-gray-900;
    @apply hover:bg-gray-300 hover:text-gray-900;
}

/* selected year & month in year picker */
.react-datepicker
    .react-datepicker__year-text.react-datepicker__year-text--selected,
.react-datepicker
    .react-datepicker__month-text.react-datepicker__month-text--selected {
    @apply bg-gray-900 text-gray-0;
    @apply hover:bg-gray-900/80 hover:text-gray-0;
}

/* year and month dropdown arrow */
.react-datepicker .react-datepicker__year-read-view--down-arrow,
.react-datepicker .react-datepicker__month-read-view--down-arrow {
    @apply top-[5px] h-[7px] w-[7px] border-r-[1.5px] border-t-[1.5px] border-gray-400;
}

/* sub-header containing year and month dropdown */
.react-datepicker
    .react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown
    ~ .react-datepicker__header__dropdown {
    @apply my-2 grid grid-cols-2 divide-x divide-gray-300 text-sm;
}

/* month and year dropdown button in sub-header */
.react-datepicker .react-datepicker__month-dropdown-container--scroll,
.react-datepicker .react-datepicker__year-dropdown-container--scroll {
    @apply inline-flex justify-center;
}

/* month and year dropdown */
.react-datepicker .react-datepicker__year-dropdown,
.react-datepicker .react-datepicker__month-dropdown {
    @apply top-auto w-2/5 border border-gray-100 bg-gray-0 shadow-md;
}

/* year dropdown */
.react-datepicker .react-datepicker__year-dropdown {
    @apply h-80;
}

/* month dropdown */
.react-datepicker .react-datepicker__month-dropdown {
    @apply py-3;
}

/* month and year option */
.react-datepicker .react-datepicker__month-option,
.react-datepicker .react-datepicker__year-option {
    @apply my-1 py-1 text-sm text-gray-600;
    @apply hover:bg-gray-100 hover:text-gray-900;
}

/* first and last type of month and year option */
.react-datepicker .react-datepicker__month-option:first-of-type,
.react-datepicker .react-datepicker__month-option:last-of-type,
.react-datepicker .react-datepicker__year-option:first-of-type,
.react-datepicker .react-datepicker__year-option:last-of-type {
    @apply rounded-none;
}

/* selected month and year in dropdown */
.react-datepicker .react-datepicker__month-option--selected_month,
.react-datepicker .react-datepicker__year-option--selected_year {
    @apply bg-gray-200 text-gray-900;
    @apply [&>span]:hidden;
}
.react-datepicker .react-datepicker__day:hover,
.react-datepicker .react-datepicker__month-text:hover,
.react-datepicker .react-datepicker__quarter-text:hover,
.react-datepicker .react-datepicker__year-text:hover {
    @apply bg-gray-200 text-gray-900;
}

/* disabled item */
.react-datepicker .react-datepicker__day--disabled {
    @apply cursor-not-allowed text-gray-300 hover:bg-transparent hover:text-gray-300 dark:hover:text-gray-300;
}

.react-datepicker .react-datepicker__year-text--disabled {
    @apply cursor-not-allowed text-gray-300 hover:bg-transparent dark:hover:text-gray-300;
}

/*  Event Calendar Date Picker Styles */

.date-picker-event-calendar .react-datepicker {
    @apply dark:bg-gray-200;
}

.date-picker-event-calendar .react-datepicker__day-names {
    @apply max-sm:flex;
}

.date-picker-event-calendar
    .react-datepicker__day-names
    .react-datepicker__day-name {
    @apply max-sm:m-0.5 max-sm:w-[calc(100%_/_7)] max-sm:text-xs;
}
.date-picker-event-calendar .react-datepicker__navigation,
.date-picker-event-calendar .react-datepicker__navigation span::before {
    @apply dark:!border-gray-500;
}

.date-picker-event-calendar
    .react-datepicker__navigation.react-datepicker__navigation--next.react-datepicker__navigation--next--with-time {
    @apply max-sm:!-me-4 sm:!me-11;
}

.date-picker-event-calendar
    .react-datepicker__navigation.react-datepicker__navigation--previous {
    @apply max-sm:ms-1;
}
.date-picker-event-calendar
    .react-datepicker__tab-loop
    .react-datepicker-popper
    .react-datepicker {
    @apply max-sm:flex;
}

.date-picker-event-calendar
    .react-datepicker__tab-loop
    .react-datepicker-popper
    .react-datepicker
    .react-datepicker__month-container {
    @apply max-sm:px-0;
}

.date-picker-event-calendar
    .react-datepicker__tab-loop
    .react-datepicker-popper
    .react-datepicker
    .react-datepicker__month-container
    .react-datepicker__month {
    @apply max-sm:mx-0;
}

.date-picker-event-calendar
    .react-datepicker__tab-loop
    .react-datepicker-popper
    .react-datepicker
    .react-datepicker__month-container
    .react-datepicker__header {
    @apply dark:bg-[unset];
}
.date-picker-event-calendar
    .react-datepicker__tab-loop
    .react-datepicker-popper
    .react-datepicker
    .react-datepicker__month-container
    .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day {
    @apply max-sm:m-1 max-sm:flex max-sm:aspect-square max-sm:w-[23.3px] max-sm:items-center max-sm:justify-center max-sm:text-xs;
}

.date-picker-event-calendar
    .react-datepicker__tab-loop
    .react-datepicker-popper
    .react-datepicker
    .react-datepicker__month
    .react-datepicker__week {
    @apply max-sm:mx-0.5 max-sm:flex;
}

.date-picker-event-calendar
    .react-datepicker__time-container
    .react-datepicker__header {
    @apply dark:bg-[unset];
}

.date-picker-event-calendar .react-datepicker__time-container {
    @apply max-sm:p-1 max-sm:ps-0;
}

.date-picker-event-calendar
    .react-datepicker__time-container
    .react-datepicker__time {
    @apply dark:bg-gray-300/30;
}

.date-picker-event-calendar
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    @apply max-sm:!w-[unset];
}

.date-picker-event-calendar
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list::-webkit-scrollbar {
    @apply w-1 bg-gray-100;
}

.date-picker-event-calendar
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list::-webkit-scrollbar-thumb {
    @apply rounded-full bg-gray-400;
}

.date-picker-event-calendar
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list {
    @apply overflow-x-hidden;
}

.date-picker-event-calendar .react-datepicker__time-container {
    @apply max-sm:!pb-1;
}

.date-picker-event-calendar
    .react-datepicker__time-container
    .react-datepicker-time__header {
    @apply font-medium;
}

.date-picker-event-calendar .react-datepicker__week .react-datepicker__day {
    @apply dark:hover:bg-gray-400;
}

.date-picker-event-calendar .react-datepicker__week .react-datepicker__day {
    @apply !rounded-full;
}

.date-picker-event-calendar
    .react-datepicker__week
    .react-datepicker__day.react-datepicker__day--selected {
    @apply dark:hover:bg-gray-400;
}
.date-picker-event-calendar
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list
    .react-datepicker__time-list-item {
    @apply dark:hover:bg-gray-100 max-sm:my-1 max-sm:!h-[unset] max-sm:!px-1 max-sm:!py-0 max-sm:text-[10px];
}

/* ................................................... */
/* table placeholder / empty style */
/* ................................................... */
.rc-table-placeholder .rc-table-expanded-row-fixed {
    @apply !w-full;
}

th.rc-table-cell-fix-right {
    @apply bg-gray-100;
}

td.rc-table-cell-fix-right {
    @apply bg-gray-0;
}

.rc-table-cell-fix-right-first,
.rc-table-cell-fix-right-last {
    box-shadow: -1px 0 black;
}

.rc-table-ping-right .rc-table-cell-fix-right-first:after,
.rc-table-ping-right .rc-table-cell-fix-right-last:after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.2);
}

.rc-table-cell-fix-right-first:after,
.rc-table-cell-fix-right-last:after {
    content: '';
    transform: translate(-100%);
    @apply pointer-events-none absolute -bottom-[1px] -left-[1px] top-0 w-5;
}

.rc-pagination-prev:not(.rc-pagination-disabled) > div,
.rc-pagination-next:not(.rc-pagination-disabled) > div,
.rc-pagination-jump-prev:not(.rc-pagination-disabled) > div,
.rc-pagination-jump-next:not(.rc-pagination-disabled) > div {
    @apply text-gray-700;
}

/*
* All form inputs common css
*/
.isomorphic-form label > span,
.isomorphic-form .rizzui-phone-number > label {
    @apply font-medium;
}

.isomorphic-form label > div {
    @apply leading-none;
}

.isomorphic-form label > div,
.isomorphic-form label input,
.isomorphic-form label select {
    @apply !text-sm;
}

/*
* Draft Text Editor
*/
.text_editor .rdw-editor-wrapper .rdw-editor-toolbar {
    @apply mb-0 rounded-none rounded-tl-md rounded-tr-md border-gray-300 px-1.5 py-2 text-sm;
}

.text_editor .rdw-editor-wrapper .rdw-editor-main {
    @apply max-h-[180px] min-h-[120px] rounded-bl-md rounded-br-md border-b border-l border-r border-gray-300;
}

.text_editor .rdw-editor-wrapper .rdw-editor-main .public-DraftEditor-content {
    @apply px-4 py-3 text-gray-700;
}

.text_editor .rdw-editor-wrapper .rdw-editor-toolbar > div {
    @apply m-1.5 rounded border border-gray-100 @lg:m-1;
}

.text_editor
    .rdw-editor-wrapper
    .rdw-editor-toolbar
    > div
    > .rdw-option-wrapper {
    @apply h-9 w-10 w-9 border-0 border-r border-gray-100 last:border-r-0 hover:bg-gray-50 hover:shadow-none @lg:m-0;
}

.text_editor
    .rdw-editor-wrapper
    .rdw-editor-toolbar
    > div
    > .rdw-option-wrapper.rdw-option-active {
    @apply bg-gray-100 shadow-none;
}

.text_editor
    .rdw-editor-wrapper
    .rdw-editor-main
    .public-DraftStyleDefault-block {
    @apply m-0;
}

.radial-progress-bar {
    background: radial-gradient(
            closest-side,
            #f1f1f1 85%,
            transparent 90% 100%
        ),
        conic-gradient(var(--progress-bg) var(--progress-value), #ddd 0);
}

/** All toolbar buttons are inside of .ql-formats */
.ql-formats button,
.ql-formats > span {
    position: relative;
    /** Set a tooltip with css pseudo-elements, when buttons are hover, active or focus */
}

.ql-formats button:hover::before,
.ql-formats > span:hover::before {
    content: '';
    @apply absolute left-1/2 top-[-9px] z-10 -translate-x-1/2 transform border-x-[7px] border-y-[6px] border-solid border-transparent border-t-[#0d1e42];
}

.ql-formats button:hover::after,
.ql-formats > span:hover::after {
    @apply absolute left-1/2 top-[-41px] z-50 -translate-x-1/2 whitespace-nowrap rounded-md bg-[#0d1e42] px-2.5 py-2 text-xs text-white;
}

/** Set tooltip content for toolbar options **/
.ql-bold:hover::after {
    content: 'Bold';
}

.ql-italic:hover::after {
    content: 'Italic';
}

.ql-underline:hover::after {
    content: 'Underline';
}

.ql-strike:hover::after {
    content: 'Strikeout';
}

.ql-link:hover::after {
    content: 'Hyperlink';
}

.ql-blockquote:hover::after {
    content: 'Quote';
}

.ql-code-block:hover::after {
    content: 'Code Block';
}

.ql-list[value='bullet']:hover::after {
    content: 'Bulleted List';
}

.ql-list[value='ordered']:hover::after {
    content: 'Numbered List';
}

.ql-script[value='sub']:hover::after {
    content: 'Sub Script';
}

.ql-script[value='super']:hover::after {
    content: 'Super Script';
}

.ql-indent[value='-1']:hover::after {
    content: 'Indent -1';
}

.ql-indent[value='+1']:hover::after {
    content: 'Indent +1';
}

.ql-color:hover::after {
    content: 'Text Color';
}

.ql-background:hover::after {
    content: 'Background Color';
}

.ql-align:hover::after {
    content: 'Text Align';
}

.ql-clean:hover::after {
    content: 'Clear Formatting';
}

.ql-font:hover::after {
    content: 'Font Family';
}

/* react-quill responsive styles */
/* .react-quill .ql-toolbar {
  @apply flex 3xl:block 3xl:max-w-none;
}
.react-quill .ql-snow .ql-formats {
  @apply flex lg:inline-flex lg:items-center;
} */

/* social btn shadow  */
.social-btn-shadow {
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))
        drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1));
}

.social-btn-shadow:hover {
    filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.05))
        drop-shadow(0px 20px 25px rgba(0, 0, 0, 0.05));
}

/* profile card swiper pagination  */
.profileModalCarousel .swiper-pagination .swiper-pagination-bullet,
.profileCarousel .swiper-pagination .swiper-pagination-bullet {
    @apply h-2 w-2 rounded-full bg-white opacity-60 transition-all;
}

.profileModalCarousel .swiper-pagination .swiper-pagination-bullet-active,
.profileCarousel .swiper-pagination .swiper-pagination-bullet-active {
    @apply w-4 rounded-xl bg-white opacity-100;
}

/* colors filter  */
.colors-filter input[type='checkbox']:checked ~ svg {
    @apply !hidden;
}

.rc-table-fixed-header thead th.rc-table-cell:nth-last-child(2) {
    @apply rounded-ee-lg rounded-se-lg;
}

/* ................................................... */
/* Recharts Base Styling */
/* ................................................... */
.recharts-wrapper .recharts-cartesian-axis-line {
    @apply stroke-gray-200;
}

.recharts-wrapper .recharts-cartesian-axis-tick-value {
    @apply fill-gray-400 text-xs;
}

.recharts-wrapper .recharts-cartesian-grid line {
    @apply stroke-gray-300;
}

.recharts-wrapper .recharts-tooltip-cursor {
    @apply opacity-20;
}

.recharts-wrapper .recharts-tooltip-cursor {
    @apply dark:opacity-10;
}

.recharts-wrapper .recharts-brush rect:first-child {
    @apply dark:fill-gray-400/50;
}

input::placeholder,
textarea::placeholder {
    @apply dark:!text-gray-300;
}

/* ................................................... */
/* Google Map Styles */
/* ................................................... */

/* .gmnoprint {
  @apply hidden;
} */
div[style='z-index: 1000001;'],
.gm-style-cc {
    @apply hidden;
}

.pac-container {
    @apply mt-4 rounded-lg border-t-0;
}

.pac-container:after {
    content: none;
}

.pac-item {
    @apply px-3 py-1.5 font-inter;
}

/* google location search auto complete  */
/* .pac-container {
  @apply w-full rounded-lg border border-gray-100 shadow after:hidden;
}
.pac-item {
  @apply cursor-pointer py-3 pl-3 text-sm font-normal text-gray-300;
}
.pac-matched {
  @apply text-sm text-gray-900;
}
.pac-icon {
  @apply mt-0;
} */

/* checkout page payment card gradient  */
.card-gradient {
    background-image: linear-gradient(120deg, #fff 45%, #f5f5f5);
}

#nprogress .bar {
    @apply !z-[9999] !bg-primary;
}

/* ................................................... */
/* React Big Calendar Styles */
/* ................................................... */
.rbc-month-view {
    @apply border-muted;
}

.rbc-header {
    @apply h-12 border-muted py-3.5 text-[13px] font-semibold uppercase text-gray-400 lg:px-7;
}

:is(
    .rbc-header + .rbc-header,
    .rbc-day-bg + .rbc-day-bg,
    .rbc-month-row + .rbc-month-row,
    .rbc-timeslot-group
) {
    @apply border-muted;
}

.rbc-day-bg {
    @apply sm:px-5;
}

.rbc-date-cell {
    @apply p-2.5 text-left text-sm font-medium text-gray-700 md:p-5 md:text-lg;
}

.rbc-day-bg.rbc-off-range-bg {
    @apply bg-gray-50;
}

.rbc-off-range {
    @apply text-gray-400;
}

.rbc-time-header.rbc-overflowing,
.rbc-time-view,
.rbc-time-content,
.rbc-time-header-content {
    @apply border-t border-muted;
}

.rbc-today {
    @apply bg-blue-lighter/10;
}

.rbc-day-slot .rbc-time-slot,
.rbc-timeslot-group {
    @apply border-muted dark:!border-gray-400;
}

.rbc-day-slot .rbc-time-slot {
    @apply border-t-0;
}

.rbc-day-slot .rbc-event {
    @apply !h-auto border-0;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
    @apply rounded-md bg-primary px-2 py-1.5 text-sm font-medium;
}

.rbc-time-view .rbc-row {
    @apply h-12;
}

.rbc-time-content > * + * > * {
    @apply border-l border-muted;
}
.rbc-timeslot-group {
    @apply border-b border-muted;
}

.rbc-time-header.rbc-overflowing {
    @apply !mr-0 border-r border-transparent lg:mr-3.5;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
    flex-flow: column;
}

.rbc-time-header-content .rbc-row-content {
    @apply mt-5;
}

.rbc-time-header-gutter {
    @apply !w-20 !max-w-full;
}

.rbc-time-gutter .rbc-timeslot-group {
    @apply px-3.5 py-2 font-medium text-gray-900;
}

.rbc-timeslot-group {
    @apply font-medium text-gray-900;
}

.rbc-label {
    @apply px-2;
}

.rbc-day-slot .rbc-time-slot + .rbc-time-slot {
    @apply border-t border-muted;
}

.rbc-timeslot-group {
    @apply min-h-[80px] border-muted;
}
.rbc-toolbar {
    @apply mb-0 rounded-t-md border border-b-0 border-muted px-4 py-6;
}

.rbc-btn-group {
    @apply rounded-md border border-muted;
}

.rbc-btn-group button {
    @apply !m-0 !rounded-md border-0 text-sm font-medium text-gray-500 shadow-none hover:bg-white hover:text-gray-800 focus:bg-white active:bg-white active:shadow-none dark:hover:bg-gray-100 dark:focus:bg-gray-100 dark:focus:text-white dark:active:bg-gray-100 dark:active:text-white;
}

.rbc-toolbar button.rbc-active {
    @apply border-primary bg-primary text-primary-foreground shadow-none;
}

.rbc-toolbar-label {
    @apply font-lexend text-xl font-medium text-gray-900 dark:text-white;
}

.rbc-agenda-view {
    @apply border;
}

.rbc-agenda-view,
.rbc-agenda-view table.rbc-agenda-table,
.rbc-agenda-view table.rbc-agenda-table thead > tr > th,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    @apply border-muted;
}

.rbc-agenda-view table.rbc-agenda-table {
    @apply border-0 border-b;
}

.rbc-agenda-empty {
    @apply p-4 text-center;
}

/* react-colorful */
.react-colorful {
    @apply gap-3;
}

.react-colorful .react-colorful__pointer {
    @apply h-4 w-4;
}

.react-colorful .react-colorful__saturation,
.react-colorful .react-colorful__hue {
    @apply rounded-md;
}

.react-colorful .react-colorful__alpha,
.react-colorful .react-colorful__hue {
    @apply h-3 rounded-md;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* pos top slider bullets style */

.pos-all-items-top-slider .swiper-pagination {
    @apply !-bottom-8;
}

.pos-all-items-top-slider .swiper-pagination span {
    @apply !mx-[3px] !h-2 !w-2.5 !rounded-full !bg-gray-600 !duration-200;
}
.pos-all-items-top-slider
    .swiper-pagination
    span.swiper-pagination-bullet-active {
    @apply !w-4 !bg-gray-400;
}

/* react calendar customize style */
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    @apply hidden;
}
.react-calendar__navigation {
    @apply items-center;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
    @apply h-7 w-7 !min-w-[28px] rounded-md !border !border-solid !border-gray-200 !bg-transparent;
}
.react-calendar__navigation__label {
    @apply text-sm font-semibold text-gray-800 disabled:!bg-transparent lg:text-base;
}
.react-calendar__month-view__days {
    @apply gap-x-1 gap-y-2;
}
.react-calendar__month-view__days__day {
    @apply flex h-9 w-9 items-center justify-center rounded-full;
}
.react-calendar__month-view__weekdays {
    @apply mb-4 !text-sm !capitalize;
}
.react-calendar__month-view__weekdays__weekday > abbr {
    @apply font-semibold text-gray-500 no-underline;
}
.react-calendar__tile {
    @apply !basis-[calc(14.2857%-4px)] !text-sm;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    @apply !bg-transparent;
}
.react-calendar__tile--active,
.react-calendar__tile--now {
    @apply !bg-[#2B7F75];
}
.react-calendar__tile--now {
    @apply !text-gray-0;
}
.react-calendar__month-view__days__day--weekend {
    @apply !text-red-dark;
}

/* job dashboard calender style  */
.job-schedule-calendar {
    @apply !w-full !border-none dark:bg-transparent;
}
.job-schedule-calendar .react-calendar__navigation {
    @apply justify-between px-5 lg:px-7;
}
.job-schedule-calendar .react-calendar__navigation__arrow {
    @apply inline-flex items-center justify-center text-[22px] leading-none;
}
.job-schedule-calendar .react-calendar__navigation__next2-button,
.job-schedule-calendar .react-calendar__navigation__prev2-button {
    @apply hidden;
}
.job-schedule-calendar .react-calendar__month-view__weekdays {
    @apply mb-0;
}
.job-schedule-calendar .react-calendar__month-view__weekdays > div {
    @apply mb-0 border border-r-0 border-gray-200 bg-gray-50 py-4 font-inter first:border-l-0;
}
.job-schedule-calendar .react-calendar__month-view__days {
    @apply !grid !grid-cols-7 gap-0;
}
.job-schedule-calendar .react-calendar__month-view__days > button {
    @apply h-full !w-full rounded-none border border-s-0 border-t-0 border-solid border-gray-200 px-0 py-4 font-inter dark:hover:!bg-gray-100 dark:focus:!bg-gray-100 [&:nth-child(7n)]:border-r-0;
}
.job-schedule-calendar button.react-calendar__tile--active,
.job-schedule-calendar button.react-calendar__tile--hasActive,
.job-schedule-calendar button.react-calendar__tile--now {
    @apply !bg-transparent !text-gray-600  hover:!bg-[#e6e6e6] dark:hover:!bg-gray-100;
}
.job-schedule-calendar button.react-calendar__month-view__days__day {
    @apply relative;
}

/* job schedule calendar months view */
.job-schedule-calendar .react-calendar__year-view__months {
    @apply !grid !grid-cols-4 gap-0 border-t border-gray-200;
}
.job-schedule-calendar .react-calendar__year-view__months > button {
    @apply border border-s-0 border-t-0 border-solid border-gray-200 dark:hover:bg-gray-100 dark:focus:bg-gray-100 [&:nth-child(4n)]:border-r-0;
}

/* job schedule calendar decades view */
.job-schedule-calendar .react-calendar__decade-view__years {
    @apply !grid !grid-cols-5 gap-0 border-t border-gray-200;
}
.job-schedule-calendar .react-calendar__decade-view__years > button {
    @apply border border-s-0 border-t-0 border-solid border-gray-200 dark:hover:bg-gray-100 dark:focus:bg-gray-100 dark:disabled:bg-gray-100 [&:nth-child(5n)]:border-r-0;
}

/* job schedule calendar century view */
.job-schedule-calendar .react-calendar__century-view__decades {
    @apply !grid !grid-cols-4 gap-0 border-t border-gray-200;
}
.job-schedule-calendar .react-calendar__century-view__decades > button {
    @apply border border-s-0 border-t-0 border-solid border-gray-200 dark:hover:bg-gray-100 dark:focus:bg-gray-100 dark:disabled:bg-gray-100 [&:nth-child(4n)]:border-r-0;
}

/* invoice builder custom styles  */
@media print {
    @page {
        size: auto;
        margin: 0mm;
    }
    html,
    body {
        margin: 0 !important;
        padding: 0 !important;
    }

    .print-container .invoice-print-table-header {
        @apply !rounded-t-md !bg-gray-100;
        print-color-adjust: exact;
    }

    .print-container table thead {
        display: table-header-group;
    }
    .print-container table tfoot {
        display: table-footer-group;
    }
}

.ck-content {
    font-family: var(--font-lexend);
    line-height: 1.6;
    word-break: break-word;
    min-height: 100px;
}

.editor-container_classic-editor .editor-container__editor {
    min-width: 795px;
    max-width: 795px;
}

.ck-content h3.category {
    font-family: var(--font-lexend);
    font-size: 20px;
    font-weight: bold;
    color: #555;
    letter-spacing: 10px;
    margin: 0;
    padding: 0;
}

.ck-content h2.document-title {
    font-family: var(--font-lexend);
    font-size: 50px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    border: 0;
}

.ck-content h3.document-subtitle {
    font-family: var(--font-lexend);
    font-size: 20px;
    color: #555;
    margin: 0 0 1em;
    font-weight: bold;
    padding: 0;
}

.ck-content p.info-box {
    --background-size: 30px;
    --background-color: #e91e63;
    padding: 1.2em 2em;
    border: 1px solid var(--background-color);
    background: linear-gradient(
            135deg,
            var(--background-color) 0%,
            var(--background-color) var(--background-size),
            transparent var(--background-size)
        ),
        linear-gradient(
            135deg,
            transparent calc(100% - var(--background-size)),
            var(--background-color) calc(100% - var(--background-size)),
            var(--background-color)
        );
    border-radius: 10px;
    margin: 1.5em 2em;
    box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
    font-family: var(--font-lexend);
    font-style: normal;
    float: right;
    width: 35%;
    position: relative;
    border: 0;
    overflow: visible;
    z-index: 1;
    margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
    content: '“';
    position: absolute;
    top: -37px;
    left: -10px;
    display: block;
    font-size: 200px;
    color: #e7e7e7;
    z-index: -1;
    line-height: 1;
}

.ck-content blockquote.side-quote p {
    font-size: 2em;
    line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
    font-size: 1.3em;
    text-align: right;
    color: #555;
}

.ck-content span.marker {
    background: yellow;
}

.ck-content span.spoiler {
    background: #000;
    color: #000;
}

.ck-content span.spoiler:hover {
    background: #000;
    color: #fff;
}

.ck-content pre.fancy-code {
    border: 0;
    margin-left: 2em;
    margin-right: 2em;
    border-radius: 10px;
}

.ck-content pre.fancy-code::before {
    content: '';
    display: block;
    height: 13px;
    background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
    margin-bottom: 8px;
    background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
    background: #272822;
    color: #fff;
    box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
    background: #dddfe0;
    color: #000;
    box-shadow: 5px 5px 0 #b3b3b3;
}

.ck-content a {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.ck-content ul {
    padding: 0 40px;
}
.ck-content ol {
    padding: 0 40px;
}
